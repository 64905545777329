module.exports = {
  title: 'Dev Blog', // Required
  author: 'Nicholas', // Required
  description: 'Full-stack Web Developer',
  primaryColor: '#3498db', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://kiwinick.dev',
    github: 'https://github.com/nicholas-l',
    gitlab: 'https://gitlab.com/nicholas-l',
    twitter: 'https://twitter.com/nick1nz',
    linkedin: 'https://www.linkedin.com/in/nicholas-l',
  },
  pathPrefix: '/devblog',
  siteUrl: 'https://www.kiwinick.dev/',
};
